import React from "react";
import map from "lodash/map";
import every from "lodash/every";
import find from "lodash/find";
import classnames from "classnames";
import { setParams, getParams, navigateTo } from "../../utils/location";
import AppContainer from "../AppContainer";
import List from "../List";
import Card from "../Card";
import Button from "../Button";
import { InputGroup, TextInput } from "../Inputs";
import CalendarIcon from "../icons/Calendar.svg";
import moment from "../../utils/moment-timezone-with-data-2012-2022";
import styles from "./index.module.scss";

const GIFT_FROM = "GIFT_FROM";
const PERSONAL_MESSAGE = "PERSONAL_MESSAGE";

const FIELDS = [GIFT_FROM, PERSONAL_MESSAGE];

const InitialState = FIELDS.reduce((o, key) => ({ ...o, [key]: null }), {});
const errorkey = (key) => `${key}_ERROR`;

export default class ShareView extends React.Component {
  state = InitialState;
  fieldsRefs = {};

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.user.shareGiftState.sent &&
      this.props.user.shareGiftState.sent
    ) {
      this.nextPageTransition = setTimeout(() => {
        navigateTo("/gift-thanks");
      }, 200);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextPageTransition);
  }

  registerInput = (field) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [field]: ref };
  };

  onInputError = (field) => (error) =>
    this.setState({ [errorkey(field)]: error });

  onInputValid = (field) => (value) => {
    this.setState({ [errorkey(field)]: null, [field]: value });
  };

  shareGift = () => {
    const {
      user: {
        shareGiftState: { contactDetails },
      },
    } = this.props;

    Promise.all(
      map(
        FIELDS,
        (field) =>
          new Promise((resolve) =>
            this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            }),
          ),
      ),
    ).then(() => {
      if (every(FIELDS, this.isValidField)) {
        console.log("Valid Form");
        this.props.shareGift({
          fromUserName: this.state[GIFT_FROM],
          personalMessage: this.state[PERSONAL_MESSAGE],
          toUserPhonenumber: contactDetails.phoneNumber,
          toUserName: contactDetails.fullName,
        });
      }
    });
  };

  render() {
    const {
      appStyles,
      user: {
        gifts,
        shareGiftState: { giftId, contactDetails, sending, sent, error },
        userDetails,
      },
      T,
      keyboardOpen,
    } = this.props;
    const { GiftActionHeader, GiftActionTitle } = appStyles;

    const getInputPropsFor = (inputId, refKey = "refEl") => ({
      [refKey]: this.registerInput(inputId),
      onValid: this.onInputValid(inputId),
      onError: this.onInputError(inputId),
    });

    const gift = find(gifts, (gift) => {
      return gift.couponInstance.id === giftId;
    });

    return giftId && contactDetails ? (
      <AppContainer.Content appStyles={appStyles}>
        <h2 style={{ ...GiftActionTitle, marginTop: 20, marginBottom: 0 }}>
          {`${T("Send")} ` +
            (contactDetails.fullName
              ? contactDetails.fullName
              : contactDetails.phoneNumber) +
            ` ${T("a Reward")}`}
        </h2>

        <Card
          appStyles={appStyles}
          classNames={classnames(styles.HideOnMobile, styles.CardDesktop)}
        >
          <List.Card.Image height={350} width="100%">
            <img
              height={350}
              width="100%"
              style={{
                maxWidth: "100%",
                maxHeight: "auto",
                objectFit: "cover",
              }}
              src={gift.couponImagePath && gift.couponImagePath.url}
            />
          </List.Card.Image>
          <Card.Content>
            <Card.Title style={{ padding: "5px" }} appStyles={appStyles}>
              {gift.couponInstance.coupon.title}
            </Card.Title>
            <Card.Description style={{ color: "#555", padding: "5px" }}>
              {T(gift.couponInstance.coupon.description)}
              <br />
              <br />
              {gift.expirationDate && (
                <span>
                  <CalendarIcon />
                  {` ${T("Valid until:")} ` +
                    moment(new Date(gift.expirationDate)).format("MMM Do YY")}
                </span>
              )}
              <h2
                style={{
                  ...GiftActionHeader,
                  textAlign: appStyles.rtl ? "right" : "left",
                  marginLeft: 0,
                  marginBottom: 5,
                }}
              >
                {T("This reward is from")}
              </h2>
              <TextInput
                {...getInputPropsFor(GIFT_FROM)}
                placeholder={T("Please write your name")}
                initialValue={userDetails.data.name}
                appStyles={appStyles}
              />
              <h2
                style={{
                  ...GiftActionHeader,
                  textAlign: appStyles.rtl ? "right" : "left",
                  marginLeft: 0,
                  marginBottom: 5,
                }}
              >
                {T("Personal Message")}
              </h2>
              <TextInput
                {...getInputPropsFor(PERSONAL_MESSAGE)}
                placeholder={T("Write a personal message to your friend!")}
                appStyles={appStyles}
              />
            </Card.Description>
            <AppContainer.Footer.Button
              onClick={this.shareGift}
              appStyles={appStyles}
              loading={sending}
              completed={sent}
            >
              {T("Share Reward")}
            </AppContainer.Footer.Button>
          </Card.Content>
        </Card>

        <List classNames={styles.HideOnDesktop}>
          <List.Card
            style={{
              flexDirection: "column",
              overflow: "hidden",
            }}
            appStyles={appStyles}
          >
            <List.Card.Image height={100} width="100%">
              <img
                height={100}
                width="100%"
                style={{
                  maxWidth: "100%",
                  maxHeight: "auto",
                  objectFit: "cover",
                }}
                src={gift.couponImagePath && gift.couponImagePath.url}
              />
            </List.Card.Image>

            <List.Card.Content>
              <List.Card.Title style={{ padding: "5px" }} appStyles={appStyles}>
                {gift.couponInstance.coupon.title}
              </List.Card.Title>
              <List.Card.Description style={{ color: "#555", padding: "5px" }}>
                <List.Card.Description.ExtraInfo>
                  {T(gift.couponInstance.coupon.description)}
                  <br />
                  <br />
                  {gift.expirationDate && (
                    <span>
                      <CalendarIcon />
                      {` ${T("Valid until:")} ` +
                        moment(new Date(gift.expirationDate)).format(
                          "MMM Do YY",
                        )}
                    </span>
                  )}
                </List.Card.Description.ExtraInfo>
              </List.Card.Description>
            </List.Card.Content>
          </List.Card>
          <List.Card appStyles={appStyles}>
            <List.Card.Content>
              <h2
                style={{
                  ...GiftActionHeader,
                  textAlign: appStyles.rtl ? "right" : "left",
                  marginLeft: 0,
                  marginBottom: 5,
                }}
              >
                {T("This reward is from")}
              </h2>
              <TextInput
                {...getInputPropsFor(GIFT_FROM)}
                placeholder={T("Please write your name")}
                initialValue={userDetails.data.name}
                appStyles={appStyles}
              />
              <h2
                style={{
                  ...GiftActionHeader,
                  textAlign: appStyles.rtl ? "right" : "left",
                  marginLeft: 0,
                  marginBottom: 5,
                }}
              >
                {T("Personal Message")}
              </h2>
              <TextInput
                {...getInputPropsFor(PERSONAL_MESSAGE)}
                placeholder={T("Write a personal message to your friend!")}
                appStyles={appStyles}
              />
            </List.Card.Content>
          </List.Card>
        </List>
        <AppContainer.Footer
          relativePosition={keyboardOpen}
          ref={(el) => {
            this.footer = el;
          }}
          appStyles={appStyles}
          transparentGradient
          center
          classNames={styles.HideOnDesktop}
        >
          <AppContainer.Footer.Button
            onClick={this.shareGift}
            appStyles={appStyles}
            loading={sending}
            completed={sent}
          >
            {T("Share Reward")}
          </AppContainer.Footer.Button>
        </AppContainer.Footer>
      </AppContainer.Content>
    ) : (
      <AppContainer.Content />
    );
  }
}
